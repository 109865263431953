@font-face {
	font-family: 'Circular Std';
	src: url('../fonts/CircularStd-MediumItalic.eot');
	src: local('Circular Std Medium Italic'), local('CircularStd-MediumItalic'), url('../fonts/CircularStd-MediumItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/CircularStd-MediumItalic.woff2') format('woff2'), url('../fonts/CircularStd-MediumItalic.woff') format('woff'), url('../fonts/CircularStd-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}
@font-face {
	font-family: 'Circular Std';
	src: url('../fonts/CircularStd-Black.eot');
	src: local('Circular Std Black'), local('CircularStd-Black'), url('../fonts/CircularStd-Black.eot?#iefix') format('embedded-opentype'), url('../fonts/CircularStd-Black.woff2') format('woff2'), url('../fonts/CircularStd-Black.woff') format('woff'), url('../fonts/CircularStd-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}
@font-face {
	font-family: 'Circular Std';
	src: url('../fonts/CircularStd-Medium.eot');
	src: local('Circular Std Medium'), local('CircularStd-Medium'), url('../fonts/CircularStd-Medium.eot?#iefix') format('embedded-opentype'), url('../fonts/CircularStd-Medium.woff2') format('woff2'), url('../fonts/CircularStd-Medium.woff') format('woff'), url('../fonts/CircularStd-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'Circular Std';
	src: url('../fonts/CircularStd-Bold.eot');
	src: local('Circular Std Bold'), local('CircularStd-Bold'), url('../fonts/CircularStd-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/CircularStd-Bold.woff2') format('woff2'), url('../fonts/CircularStd-Bold.woff') format('woff'), url('../fonts/CircularStd-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}
@font-face {
	font-family: 'Circular Std';
	src: url('../fonts/CircularStd-BlackItalic.eot');
	src: local('Circular Std Black Italic'), local('CircularStd-BlackItalic'), url('../fonts/CircularStd-BlackItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/CircularStd-BlackItalic.woff2') format('woff2'), url('../fonts/CircularStd-BlackItalic.woff') format('woff'), url('../fonts/CircularStd-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}
@font-face {
	font-family: 'Circular Std Book';
	src: url('../fonts/CircularStd-BookItalic.eot');
	src: local('Circular Std Book Italic'), local('CircularStd-BookItalic'), url('../fonts/CircularStd-BookItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/CircularStd-BookItalic.woff2') format('woff2'), url('../fonts/CircularStd-BookItalic.woff') format('woff'), url('../fonts/CircularStd-BookItalic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}
@font-face {
	font-family: 'Circular Std';
	src: url('../fonts/CircularStd-BoldItalic.eot');
	src: local('Circular Std Bold Italic'), local('CircularStd-BoldItalic'), url('../fonts/CircularStd-BoldItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/CircularStd-BoldItalic.woff2') format('woff2'), url('../fonts/CircularStd-BoldItalic.woff') format('woff'), url('../fonts/CircularStd-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}
@font-face {
	font-family: 'Circular Std Book';
	src: url('../fonts/CircularStd-Book.eot');
	src: local('Circular Std Book'), local('CircularStd-Book'), url('../fonts/CircularStd-Book.eot?#iefix') format('embedded-opentype'), url('../fonts/CircularStd-Book.woff2') format('woff2'), url('../fonts/CircularStd-Book.woff') format('woff'), url('../fonts/CircularStd-Book.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}
