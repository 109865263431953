/* -------------------------------------------------------------------------- */
/*                                 Theme                                      */
/* -------------------------------------------------------------------------- */

@import "bootstrap/scss/_functions.scss";
@import "theme/functions";

@import "bootstrap/scss/mixins";
@import "theme/mixins";

@import "user-variables";
@import "theme/variables";
@import 'theme/_utilities.scss';
@import "bootstrap/scss/variables";


/*-----------------------------------------------
|   Bootstrap Styles
-----------------------------------------------*/
@import "bootstrap";

/*-----------------------------------------------
|   Theme Styles
-----------------------------------------------*/
@import "theme/theme";

/*-----------------------------------------------
|   User Styles
-----------------------------------------------*/
@import "user";

@import '@fortawesome/fontawesome-free/css/all.css';
@import 'leaflet/dist/leaflet.css';