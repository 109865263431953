// 
// 
// user.scss
//
// Place your own theme CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

      .section-title {
    text-align: center;
    padding-bottom: 30px;
}
.section-title h2 {
    font-size: 32px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
}
.features .icon-box {
  width: 100%;
}
.features .icon-box i {
    font-size: 48px;
    float: left;
    color: #5846f9;
}

.features .icon-box p {
    font-size: 15px;
    color: #848484;
    margin-left: 60px;
}

.features .icon-box h4 {
    font-size: 20px;
    font-weight: 700;
    margin: 5px 0 10px 60px;
}


/*******************************
* MODAL AS LEFT/RIGHT SIDEBAR
* Add "left" or "right" in modal parent div, after class="modal".
* Get free snippets on bootpen.com
*******************************/


/*Left*/

.mapmodal.fade .card {
  left: -320px;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.mapmodal {
   -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 0.5s; /* Firefox < 16 */
        -ms-animation: fadein 0.5s; /* Internet Explorer */
         -o-animation: fadein 0.5s; /* Opera < 12.1 */
            animation: fadein 0.5s;
}



.topics-list {
    padding: 20px 0;
}

.topics header {
    border-bottom: 1px solid #eee;
    position: relative;
}

.icon-pages {
    background: url(images/icon-pages.png);
    background-repeat: no-repeat;
    display: inline-block;
    width: 40px;
    height: 48px;
    vertical-align: middle;
    margin-right: 10px;
}

.topics h3 a {
    font-size: 18px;
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    color: #43484d;
    text-decoration: none;
}

.topics-list .badge {
    background-color: #7dc855;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
    line-height: 25px;
    min-width: 10px;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    height: 25px;
    width: 25px;
    margin-right: 10px;
    padding: 0;
}

.topics-list ul {
    padding: 0;
}


  .topics-list li {
    list-style: none;
    padding-left: 5px;
    padding-top: 10px; }

    .topics-list li a {
      /*padding-left: 15px;*/
      color: #5e6977;
      font-size: 14px;
      font-weight: 400;
      font-family: "Roboto",sans-serif;
      text-decoration: none;
      outline: 0 none; }

    .topics-list li a:hover {
      color: #7dc855; }

  .topics-list li:before {
    /*content: "-";
    color: #b5bfc7;
    font-size: 18px;*/ 
    }
.widget {
  border: 3px solid #f7f7f7;
  border-radius: 5px;
  padding: 25px; }
  .widget h4 {
    font-size: 18px;
    font-weight: 400;
    color: #43484d;
    line-height: 25px; }
  .widget .icon {
    float: right;
    padding-right: 10px;
    padding-right: 20px; }
.widget-support-forum {
  margin-bottom: 24px;
  margin-top: 50px; }
  .widget-support-forum p {
    font-size: 16px;
    font-weight: 400;
    color: #86939e;
    padding-top: 30px;
    padding-bottom: 30px; }
